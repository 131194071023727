.datatable {
  height: auto; // 600px;
  // padding: 20px;
  background-color: var(--white);
  width: 100%;

  .datatableTitle {
    width: 100%;
    font-size: 15px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 0px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  // .cellWithImg {
  //   display: flex;
  //   align-items: center;
  //   border: none;
  //   // font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  //   // font-size: 12px !important;


  //   .cellImg {
  //     width: 32px;
  //     height: 32px;
  //     border-radius: 50%;
  //     object-fit: cover;
  //     margin-right: 20px;

  //   }
  // }

  // .cellWithStatus {
  //   padding: 5px;
  //   border-radius: 5px;

  //   &.active {
  //     background-color: rgba(0, 128, 0, 0.05);
  //     color: green;
  //   }

  //   &.pending {
  //     background-color: rgba(255, 217, 0, 0.05);
  //     color: goldenrod;
  //   }

  //   &.passive {
  //     background-color: rgba(255, 0, 0, 0.05);
  //     color: crimson;
  //   }

  //   &.in-progress {
  //     background-color: rgba(139, 231, 138, 0.05);
  //     color: #8BE78A;
  //   }

  //   &.available {
  //     background-color: rgba(0, 128, 0, 0.05);
  //     color: green;
  //   }

  //   &.completed {
  //     background-color: rgba(0, 128, 0, 0.05);
  //     color: green;
  //   }

  //   &.repair {
  //     background-color: rgba(255, 217, 0, 0.05);
  //     color: goldenrod;
  //   }

  //   &.sold {
  //     background-color: rgba(255, 0, 0, 0.05);
  //     color: crimson;
  //   }

  //   &.success {
  //     background-color: rgba(0, 128, 0, 0.05);
  //     color: green
  //   }
  // }

  // .cellAction {
  //   display: flex;
  //   align-items: center;
  //   gap: 15px;
  //   justify-content: center;

  //   .viewButton {
  //     padding: 2px 5px;
  //     border-radius: 5px;
  //     color: darkblue;
  //     border: 0px dotted rgba(0, 0, 139, 0.596);
  //     cursor: pointer;
  //   }

  //   .deleteButton {
  //     padding: 2px 5px;
  //     border-radius: 5px;
  //     color: crimson;
  //     border: 0px dotted rgba(220, 20, 60, 0.6);
  //     cursor: pointer;
  //     // font-size: 50px;
  //   }
  // }



  .datagrid {
    margin: 0px !important;
    border: 0px solid #000;
    text-align: center;
    background: #fff;
    grid-template-rows: none !important;
    grid-template-columns: none !important;

    .rdg-header-row {

      div {
        background: rgba(245, 245, 245);
        // height: 40px;
      }
    }

    div {
      font-family: 'Raleway' !important;
      font-size: 14 !important;
      //font-weight: 100 !important;
      background-color: #fff;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #000;
      border-right: none;

    }


  }

  .columnheader {
    font-weight: 500;
    background: red;
  }

  .rdg-cell {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0.2px solid rgba(0, 0, 0, 0.2) !important;

  }

  .datagrid svg {
    width: 10px
  }

  p {
    margin-bottom: 0px !important;
  }

  .cell {
    border: none;
  }
}