.navbar {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  width: 100%;
  background: black !important;
  border: 0px solid #000;

  .currentDate {
    font-size: 12px;
    font-weight: 100;
  }


  .wrapper {
    width: 100%;
    padding: 0px;
    display: flex;
    // align-items: end;
    justify-content: space-between;


    .activePage {
      font-weight: 600;

      &::first-letter {
        text-transform: uppercase
      }
    }

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        cursor: pointer;

        .text-size {
          font-size: 12px;
        }

        .icon {
          font-size: 15px;
          width: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }


  .iconSection {
    position: relative;
    width: fit-content;
    display: inline-flex;
  }

  .iconBadge {
    background: red;
    width: 15px;
    display: block;
    text-align: center;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    line-height: 15px;
    position: absolute;
    right: 0;
    bottom: 13px;
    left: 12px;
  }
}

.shadow-sm {
  box-shadow: none !important;
}

@media (max-width: 500px) {
  .navbar {
    height: 50px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;

    .wrapper {
      width: 100%;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end; //space-between;

      .search {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;

        input {
          border: none;
          outline: none;
          background: transparent;

          &::placeholder {
            font-size: 12px;
          }
        }
      }

      .items {
        display: flex;
        align-items: center;

        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
          cursor: pointer;

          .text-size {
            font-size: 12px;
          }

          .icon {
            font-size: 15px;
            width: 20px;
          }

          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }

          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
  }
}