.content {
    display: flex;
    flex: 1;

    .customerDashboard__homeContainer {
        flex: 6;

        .shadow-sm {
            box-shadow: none !important;
        }
    }



    // .sidebar {
    //     flex: 1
    // }
}