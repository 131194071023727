.sidebar {
  background-color: rgb(237, 151, 153);
  //padding: 20px;
  min-height: 100vh;
  min-width: 20%;

  // .header {
  //   background: white;
  // }

  .selectedTab {
    // clip-path: polygon(0 50%, 50% 0, 50% 100%);
    background: white;
  }

  .title {
    // color: #000
    font-weight: 500;
    font-size: 14px;
  }

  li {
    // margin-bottom: 50px;
    list-style: none;
    color: #000;
    font-size: 12px;
    text-align: justify;
    height: 80px;
    padding: 20px;

    i {
      margin-right: 10px;
      font-size: 12px !important;
      color: #000;
    }
  }

  .ri-customer-dashboard-line {
    // color: rgba(220, 13, 54, 1) !important;
    color: #050304;
    // background-color: rgba(255, 0, 0, 0.2);
    border-radius: 5px;
  }

  .active {
    color: #22ABC3 !important;
  }

  .ri-user-line {
    // color: rgba(51, 13, 220, 1) !important;
    color: #050304;
    // background-color: rgba(51, 13, 220, 0.2);
    border-radius: 5px;
  }

  .ri-car-line {
    //color: rgb(13, 192, 220) !important;
    color: #050304;
    //  background-color: rgba(13, 192,220, 0.2);
    border-radius: 5px;
    transition: all 0.5s;

    &:hover {
      background-color: rgba(13, 192, 220, 0.2);

    }
  }

  .ri-shopping-cart-2-line {
    //  color: rgb(13, 220, 13) !important;
    color: #050304;
    // background-color: rgba(13, 220, 13, 0.2);
    border-radius: 5px;
  }

  .ri-notification-4-line {
    //  color: rgb(44, 13, 220) !important;
    color: #050304;
    // background-color: rgba(13, 220, 13, 0.2);
    border-radius: 5px;
  }

  .ri-settings-5-fill {
    //color: rgb(245, 87, 9) !important;
    // background-color: rgba(13, 220, 13, 0.2);
    color: #050304;
    border-radius: 5px;
  }

  .ri-account-circle-line {
    // color: rgb(177, 14, 226) !important;
    // background-color: rgba(13, 220, 13, 0.2);
    color: #050304;
    border-radius: 5px;
  }

  .ri-logout-box-r-line {
    // color: rgb(114, 248, 114) !important;
    // background-color: rgba(13, 220, 13, 0.2);
    color: #050304;
    border-radius: 5px;
  }

  .ri-customer-service-line {
    color: #050304;
    border-radius: 5px;
  }

  span.selectedTab {
    clip-path: polygon(0 50%, 50% 0, 50% 100%) !important;
    background: rgb(243, 242, 240) !important;
    width: 50px !important;
    height: 50px !important;
    position: relative !important;
    left: calc(100% - 109px) !important;
  }
}


@media (max-width: 500px) {
  .sidebar {
    min-height: 150vh;
    min-width: 5%;

    .clear {
      display: none !important;
      opacity: 0;
    }

    .center {
      padding-left: 10px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        flex: 1;

        .title {
          font-size: 10px;
          font-weight: bold;
          color: #999;
          margin-top: 15px;
          margin-bottom: 5px;
        }

        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;

          .selectedTab {
            clip-path: polygon(0 50%, 50% 0, 50% 100%);
            background: rgba(255, 0, 0, 1); // rgba(243, 242, 240, 1);
            width: 40px;
            height: 50px;
            // position: absolute !important;
            left: 50% !important; //calc(100% - 50%);
          }

          &:hover {
            background-color: #ece8ff;
          }

          .icon {
            font-size: 1.5rem;
            font-weight: 400;
            color: #1D1D1D;
            /*#7451f8;*/

          }

          span {
            font-size: 13px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #7451f8;
        cursor: pointer;
        margin: 5px;

        &:nth-child(1) {
          background-color: whitesmoke;
        }

        &:nth-child(2) {
          background-color: #333;
        }

        &:nth-child(3) {
          background-color: darkblue;
        }
      }
    }
  }
}