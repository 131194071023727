.balance-wrapper {
    // padding: 10px;
    width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // border-right: 0px;
    border-radius: 12px;
    display: flex;

    .current-balance {
        // border: 1px solid rgba(0, 0, 0, 0.2);
        // border-radius: 12px;
        padding: 20px;

    }

    .totalBalance {
        display: flex;
        justify-content: space-between;

    }

    .fundsOnHold {
        display: flex;
        flex-direction: column;
        background: rgb(245, 245, 245);
        font-size: 12px;
        justify-content: space-around;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
    }

    .currencies {
        width: auto;
        font-size: 12px;

    }

    .figures {
        font-weight: bold;
        font-size: large;
    }

    .exchange-rate {
        font-weight: 100;
        font-size: 12px;
    }

    .total-acc-balance {
        color: red;
        font-size: 12px;
    }

}

@media (max-width: 500px) {
    .balance-wrapper {
        width: 100%;

        .current-balance {
            width: 50%;
        }

        .fundsOnHold {
            width: 50%;
        }
    }
}