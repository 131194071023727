.tableToolbar {
    display: flex;
    // flex-direction:;
    // background-image: linear-gradient(.25turn, rgba(255, 255, 255, 0.3) 10%, rgba(232, 234, 235, 0.9) 50%,
    //         rgba(255, 255, 245, 0.9) 80%);
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    // border-radius: 10px;
    height: auto; //130px;
    // flex-wrap: wrap;
    justify-content: space-between;



    .add-btn {

        // border: 1px solid transparent;
        // border-radius: 0.25rem;
        color: #212529;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
        text-align: center;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-user-select: none;
        user-select: none;
        vertical-align: middle;
        margin-top: 50px;
        background-image: linear-gradient(to right, #70bafe 0%, #998dff 11%, #70bafe 100%);
        // background: #22ABC3;
        width: 60px;
        height: 32px;
        color: #fff;
        border: 0px solid rgba(204, 241, 255, 0.5);
        border-radius: 33px;
    }

}


@media (max-width: 500px) {

    .tableToolbar,
    .tableToolbar-wrapper {
        flex-wrap: wrap;
        height: auto;
    }
}